import {
  createUseQueryHook,
  createSsrQuery,
  createSsrCache,
  createUseMutationHook,
  createUsePaginatedQueryHook,
  createCustomClient,
  createSsrMutation,
} from '@vgn-medien-holding/vgn-gql-shared-fe-queries';

const url = process.env.NEXT_PUBLIC_CMS_API_URL ?? '';
const apiToken = process.env.NEXT_PUBLIC_CMS_API_TOKEN ?? '';
const includeAuthExchange = false;

let ssrCache = createSsrCache();
let client = createCustomClient({ url, apiToken, ssrCache, includeAuthExchange });
let ssrQuery = createSsrQuery(client);
let ssrMutation = createSsrMutation(client);

const useQuery = createUseQueryHook();
const useMutation = createUseMutationHook();
const usePaginatedQuery = createUsePaginatedQueryHook();

function clearCache() {
  ssrCache = createSsrCache();
  client = createCustomClient({ url, apiToken, ssrCache, includeAuthExchange });
  ssrQuery = createSsrQuery(client);
  ssrMutation = createSsrMutation(client);
}

export { client, ssrCache, clearCache, ssrQuery, ssrMutation, useQuery, useMutation, usePaginatedQuery };
