import React, { useEffect, useMemo, useState } from 'react';
import type { AppProps } from 'next/app';
import App from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SettingsProvider, GoogleTagManager } from '@vgn-medien-holding/vgn-fe-components';
import { UrqlProvider, restoreSsrCache } from '@vgn-medien-holding/vgn-gql-shared-fe-queries';
import * as Fathom from 'fathom-client';
import packageInfos from 'package.json';
import { Empty } from '@components/templates/layouts/Empty';
import { settings } from '@src/settings/settings';
import '@src/styles/globals.css';
import AppContext from '@lib/AppContext';
import { client, ssrCache, ssrQuery } from '@lib/graphql/urql';
import { useScript } from '@lib/hooks/useScript';

const appVersion = packageInfos?.version;

const headerNavHandle = process.env.NEXT_PUBLIC_HEADER_NAV_HANDLE;
const footerNavHandle = process.env.NEXT_PUBLIC_FOOTER_NAV_HANDLE;

type ComponentWithPageLayout = AppProps & {
  Component: AppProps['Component'] & {
    PageLayout?: React.ComponentType;
  };
};

function MyApp({ Component, pageProps }: ComponentWithPageLayout) {
  const { otId, gtmId } = pageProps;
  const [searchVisible, setSearchVisible] = useState(false);
  const Layout = Component.PageLayout || Empty;
  const [containerPolyfillShouldLoad, setContainerPolyfillShouldLoad] = useState(false);
  const router = useRouter();

  restoreSsrCache({ pageProps, ssrCache });

  useEffect(() => {
    console.info(
      `%cTV-Media v${appVersion}`,
      `width: 100vw; display: block; background: linear-gradient(to bottom right, #FF0000, #550000); color: #fff; padding: 0.5rem 1.5rem ; margin: 1.5rem 2.5rem 2.5rem 0; border-radius: 3rem;`,
    );
    setContainerPolyfillShouldLoad(!('container' in document.documentElement.style));
    Fathom.load('TPPJWIUN', {
      //includedDomains: ['www.tv-media.at'],
    });

    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScript(`https://cdn.jsdelivr.net/npm/container-query-polyfill@1/dist/container-query-polyfill.modern.js`, {
    removeOnUnmount: false,
    shouldPreventLoad: !containerPolyfillShouldLoad,
  });

  const providerValues = useMemo(
    () => ({
      header: {
        navigation: pageProps.headerNavigation,
      },
      search: {
        searchVisible: searchVisible,
        setSearchVisible: setSearchVisible,
      },
      footer: {
        navigation: pageProps.footerNavigation,
      },
      magazines: pageProps.magazines,
    }),
    [pageProps.footerNavigation, pageProps.headerNavigation, pageProps.magazines, searchVisible],
  );

  return (
    <AppContext.Provider value={providerValues}>
      <SettingsProvider settings={settings}>
        <UrqlProvider value={client}>
          <Layout>
            {otId && <OneTrust otId={otId} />}
            {gtmId && <GoogleTagManager gtmId={gtmId} customSource={'https://data.tv-media.at/eeyeitdp.js?st='} />}
            <Component {...pageProps} statusCode={pageProps.errorStatus} />
          </Layout>
        </UrqlProvider>
      </SettingsProvider>
    </AppContext.Provider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const { res } = appContext.ctx;
  const appProps = await App.getInitialProps(appContext);
  if (appProps.pageProps?.errorStatus && res) {
    res.statusCode = appProps.pageProps.errorStatus;
  }

  const { GetMagazinesDocument, GetNavigationByHandlesDocument } = await import('@src/lib/graphql/generated');

  const { data: navData } = await ssrQuery({
    query: GetNavigationByHandlesDocument,
    variables: { handles: [headerNavHandle, footerNavHandle] },
  });
  const { data: magazinesData } = await ssrQuery({
    query: GetMagazinesDocument,
    variables: {},
  });

  return {
    pageProps: {
      headerNavigation: navData?.navigationByHandles?.find((nav) => nav.handle === headerNavHandle),
      footerNavigation: navData?.navigationByHandles?.find((nav) => nav.handle === footerNavHandle),
      errorStatus: res?.statusCode,
      magazines: magazinesData?.magazines,
      gtmId: process.env.NEXT_PUBLIC_GTM_ID || null,
      otId: process.env.NEXT_PUBLIC_ONETRUST_ID || null,
    },
  };
};

export default MyApp;

const OneTrust = ({ otId }: { otId: string }) => (
  <>
    <link rel="preconnect" href="https://cdn.cookielaw.org" />
    <link rel="preconnect" href="https://geolocation.onetrust.com" />
    <Script async={true} src={'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'} data-domain-script={otId} />
    <Script async={true} id={'ot-custom'}>{`function OptanonWrapper() { }`}</Script>
  </>
);
